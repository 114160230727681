
import './App.css';

function App() {
  return (
    <div className="container d-flex flex-column justify-content-around" style={{height: '200vh'}}>

       <header>
           <h1 className={'poppins-extrabold'}>Stride</h1>
           <p className={'poppins-thin'}>
               Sowftware Development
           </p>
       </header>

        <main className={'min-vh-100'}>

            <p className={'display-5 col-md-6 col-12'}>
                We craft beautiful develop  experiences
            </p>

            <div className="d-flex flex-wrap mt-5">
                <div className="col-md-3 col-12">
                    <p className={'lead pt-2'} style={{paddingRight: '20px'}}>
                        Powering Innovative Mobile and Web Apps with ❤️
                    </p>
                </div>
                <div className="col-md-9 col-12">
                    <img src="https://plus.unsplash.com/premium_photo-1683134153517-32015af21911" className={'img-fluid'} alt="cover image"/>
                </div>
            </div>

            <section id={'projects'} className={'mt-5'}>

                <h2 className={'display-1'}>Projects</h2>



                <div className="mt-5 col-md-4">
                    <span className={'display-1 text-secondary opacity-25'}>
                        #01
                    </span>
                    <h3>questionnaire.work</h3>

                    <p className={'text-dark lead'}>
                        Use a convenient and simple way to create your own questionnaires, forms, surveys for personal and corporate purposes. Try it now.
                    </p>

                    <a href="https://questionnaire.work/" className={'text-decoration-none d-flex align-items-center text-secondary'} target={'_blank'}>
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                        </svg>
                        <small className={'lead m-2'}>https://questionnaire.work</small>
                    </a>
                </div>
            </section>

        </main>

        <footer className={'d-flex justify-content-between align-items-center border-top pt-5 flex-reverse'}>
            <p>
                Copyright © 2024. Stride Sowftware Development.
            </p>

            <a href="mailto:mail@strd.pro" target={'_blank'} className={'text-secondary text-decoration-none'}>mail@strd.pro</a>
        </footer>

    </div>
  );
}

export default App;
